.btn {
  border-radius: 0;
}

#login span {
  padding-right: 10px;
}

.panel {
  border-radius: 0;
}

.panel-heading {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.language {
  margin-top: 43px;
  margin-left: 85px;
}

.language ul, .language li {
  list-style: none;
  list-style-image: none;
  margin: 0;
}

.language li:last-child {
  margin: 0;
}

.checkbox {
  line-height: 100%;
}

.asterisk_input::after {
  color: #e32;
  content: " *";
  font-size: large;
  margin: 4px 0 0 5px;
  position: absolute;
}

.btn-xs, .btn-group-xs > .btn {
  padding: 1px 4px;
}

.additional {
  padding-bottom: 10px;
}

.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.black-subtitle.gradient.gray-gradient {
  clear: both;
}

.black-subtitle {
  color: black;
  font-size: 17px;
  line-height: 140%;
  padding: 6px 0 0 4px;
  margin-bottom: 2px;
  margin-top: 13px;
}

.location {
  padding: 6px 0px 1px 4px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.location a {
  text-decoration: none;
  color: inherit;
}

.chart {
  display: inline-block;
  width: 100%;
  height: 150px;
  text-align: center;
  canvas {
    padding: 0;
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.percent {
  display: inline-block;
  line-height: 150px;
  z-index: 2;
  &:after {
    content: '%';
    margin-left: 0.1em;
    font-size: .8em;
  }
}

.modal-title {
  font-size: 16px;
}

.btn-wrap {
  position: relative;
  display: inline-block;
  font-size: 0;
  padding-bottom: 15px;
}

.btn-wrap button {
  white-space: normal;
}

.btn-wrap .closing {
  float: right;
  position: absolute;
  opacity: 0.3;
  margin-left: -10px;
  margin-top: -10px;
}

.btn-wrap .closing span {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.btn-wrap:hover .closing {
  opacity: 1;
}

.has-error .btn-default, .has-error .btn-default:hover, .has-error .cke_chrome {
  border-color: #a94442;
}

.has-error .checkbox label::before {
  border: 1px solid #a94442;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  vertical-align: middle !important;
}

.select2-search__field {
  width: 100% !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: inherit !important;
}

.select2-container--bootstrap .select2-results__option[aria-selected="true"] {
  background-color: #868686;
  color: #262626;
}

.image-preview-input {
  position: relative;
  overflow: hidden;
  margin: 0px;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.image-preview-input input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.image-preview-input-title {
  margin-left: 2px;
}

table .radio, table .checkbox {
  margin: 0;
}

.input-group[class*="col-"] {
  padding-left: 15px;
  padding-right: 15px
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 1.25s linear;
  -moz-transition: opacity 1.25s linear;
  -ms-transition: opacity 1.25s linear;
  -o-transition: opacity 1.25s linear;
  transition: opacity 1.25s linear;
}

.fade.in {
  opacity: 1;
  -webkit-transition: opacity 1.25s linear;
  -moz-transition: opacity 1.25s linear;
  -ms-transition: opacity 1.25s linear;
  -o-transition: opacity 1.25s linear;
  transition: opacity 1.25s linear;
}

.tt-menu {
  text-align: left;

}

.tt-dataset {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-menu {
  width: 100%;
  background-color: #fff;
  border: 1px solid #66afe9;
  -webkit-box-shadow: 0 5px 10px rgba(102, 175, 233, .6);
  -moz-box-shadow: 0 5px 10px rgba(102, 175, 233, .6);
  box-shadow: 0 5px 10px rgba(102, 175, 233, .6);
}

.tt-suggestion {
  padding: 6px 12px;
  font-size: 13px;
  outline: none;
}

.tt-suggestion:hover {
  color: #fff;
  background-color: #868686;
}

.tt-suggestion:hover strong {
  color: #fff;
}

.tt-suggestion div {
  margin: 0 !important;
  padding: 3px !important;
  text-align: justify;
}

.twitter-typeahead {
  display: block !important;
}

.tt-suggestion strong {
  color: #666666;
}

#scrollable-dropdown-menu .tt-dropdown-menu {
  max-height: 150px;
  overflow-y: auto;
}

.select2-results__options ul, .select2-results__options li {
  font-size: 13px;
}

.popover p {
  padding: 5px 0;
}

.select2-container--bootstrap .select2-selection {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  border-radius: 0;
}

// Hover/Focus state
.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  text-decoration: none;
  color: rgb(237, 119, 33);
  background-color: rgb(255, 255, 255);
  box-shadow: inset 0 -4px 0 0 #ed7721;
  -webkit-box-shadow: inset 0 -4px 0 0 #ed7721;
  -moz-box-shadow: inset 0 -4px 0 0 #ed7721;
}

.offers-pagination {
  padding: 5px 0;
}

.offers-pagination ul {
  margin: 0;
  border-radius: 0;
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  border-radius: 0;
}

.btn-link:hover, .btn-link:focus {
  text-decoration: none;
}

.btn-link {
  color: rgb(0, 0, 0);
}

.addthis_separator {
  display: inline-block;
}